import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@client-portal/routing';
import { ButtonComponent } from '@client-portal/ui/button';
import { DialogComponent } from '@client-portal/ui/dialog';
import { Division, DivisionCode } from '@client-portal/models/common';
import { DivisionService } from '@client-portal/services/division';
import { ChatWidgetService } from '@client-portal/services/chat-widget';

@Component({
  selector: 'clp-switch-modal',
  standalone: true,
  imports: [ButtonComponent, AsyncPipe, DialogComponent, MatDialogModule],
  templateUrl: './switch-modal.component.html',
  styleUrls: ['./switch-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchModalComponent {
  public readonly Division = Division;

  constructor(
    public divisionService: DivisionService,
    private _router: Router,
    private _dialogRef: MatDialogRef<SwitchModalComponent>,
    private _route: ActivatedRoute,
    private _chatWidgetService: ChatWidgetService,
  ) {}

  public confirm(divisionCode: DivisionCode): void {
    this.divisionService.setDivision(divisionCode);

    const route = this._route.snapshot.firstChild?.firstChild;
    const path = route?.routeConfig?.path;
    const hasChildren =
      route &&
      route.children?.length > 0 &&
      route.firstChild &&
      route.firstChild?.routeConfig?.path !== '';

    /*
     * Router does nothing when we are trying to navigate to the current route (from '/current-orders to /current-orders).
     * It can be changed, by passing navigation extras option: onSameUrlNavigation: 'reload',
     * but still it won't destroy/init the current view component -> won't reload data.
     *
     * In this case we need to reload app as we don't have any global state or event to trigger to reload data programmatically.
     * In other cases we just navigate to the 'current-orders' route, as business logic requires.
     */
    if (
      route &&
      path &&
      path === ROUTE_PATHS.currentOrders.root &&
      !hasChildren
    ) {
      window.location.reload();
    } else {
      void this._router.navigate(['/', ROUTE_PATHS.currentOrders.root]);
    }
    this._chatWidgetService.chatVisible.set('hidden');
    this._dialogRef.close();
  }
}
