import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AsyncPipe } from '@angular/common';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@client-portal/routing';
import { SelectComponent } from '@client-portal/ui/select';
import { ButtonComponent } from '@client-portal/ui/button';
import { DivisionCode } from '@client-portal/models/common';
import { DivisionService } from '@client-portal/services/division';

@Component({
  selector: 'clp-switch-account-modal',
  standalone: true,
  imports: [SelectComponent, ButtonComponent, AsyncPipe],
  templateUrl: './switch-account-modal.component.html',
  styleUrls: ['./switch-account-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchAccountModalComponent {
  public divisionControl = new FormControl<DivisionCode>(
    this._divisionService.division(),
    {
      nonNullable: true,
    },
  );
  public options = this._divisionService.divisionSelectOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA) public userName: string,
    private _dialogRef: MatDialogRef<SwitchAccountModalComponent>,
    private _divisionService: DivisionService,
    private _router: Router,
  ) {}

  public switchAccount(): void {
    this._divisionService.setDivision(this.divisionControl.value);
    void this._router.navigateByUrl(ROUTE_PATHS.currentOrders.root);
    this._dialogRef.close();
  }
}
