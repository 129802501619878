<div class="main-content switch-account">
  <h1 class="switch-account__title">Welcome {{ userName }}!</h1>
  <div class="switch-account__description">
    Which division would you like to access?
  </div>
  <ui-select [options]="options" [control]="divisionControl"></ui-select>
  <div class="switch-account__action">
    <ui-button
      (handleClick)="switchAccount()"
      [isDisabled]="!divisionControl.value"
      cssClasses="button-full-width"
    >
      Continue
    </ui-button>
  </div>
</div>
