@if (divisionService.oppositeDivision(); as oppositeDivision) {
  <ui-dialog title="Switch account">
    <section class="dialog-notes" mat-dialog-content>
      <div>
        Are you sure you want to switch to
        {{ Division[oppositeDivision] }}?
      </div>
    </section>
    <section align="center" mat-dialog-actions>
      <div class="dialog-actions">
        <ui-button
          buttonType="button-secondary"
          cssClasses="button-full-width"
          mat-dialog-close
        >
          No, cancel
        </ui-button>
        <ui-button
          cssClasses="button-full-width"
          (click)="confirm(oppositeDivision)"
          >Yes, switch</ui-button
        >
      </div>
    </section>
  </ui-dialog>
}
